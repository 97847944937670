<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:externalFilter="filter()"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
			:showCreateBtn="false"
			:showDeleteBtn="showDeleteBtn"
			:actions="filteredActions"
		></pui-datatable>
		<imofalformsgeneral-modals :modelName="modelName"></imofalformsgeneral-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import ImoFalFormsGeneralActions from './ImoFalFormsGeneralActions';
import ImoFalFormsGeneralModals from './ImoFalFormsGeneralModals';

export default {
	name: 'imofalformsgeneral-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'imofalformsgeneral-modals': ImoFalFormsGeneralModals
	},
	data() {
		return {
			modelName: 'imofalformsgeneral',
			parent: 0,
			rol: null,
			actions: ImoFalFormsGeneralActions.gridactions,
			portcallstatus: null,
			showbotons: false,
			modelColumnDefs: {
				arrivaldeparture: {
					render: (data) => {
						if (data === '1') {
							return this.$t('grid.imofalformsgeneral.isarrival');
						} else if (data === '2') {
							return this.$t('grid.imofalformsgeneral.isdeparture');
						} else {
							return '';
						}
					}
				},
				imofalformtype: {
					render: (data) => {
						switch (data) {
							case 'desctypeimofalform1':
								return this.$t('grid.imofalformsgeneral.desctypeimofalform1');
							case 'desctypeimofalform2':
								return this.$t('grid.imofalformsgeneral.desctypeimofalform2');
							case 'desctypeimofalform3':
								return this.$t('grid.imofalformsgeneral.desctypeimofalform3');
							case 'desctypeimofalform4':
								return this.$t('grid.imofalformsgeneral.desctypeimofalform4');
							case 'desctypeimofalform5':
								return this.$t('grid.imofalformsgeneral.desctypeimofalform5');
							case 'desctypeimofalform6':
								return this.$t('grid.imofalformsgeneral.desctypeimofalform6');
							case 'desctypeimofalform7':
								return this.$t('grid.imofalformsgeneral.desctypeimofalform7');
							case 'desctypeimofalform8':
								return this.$t('grid.imofalformsgeneral.desctypeimofalform11');
							default:
								return '';
						}
					}
				}
			}
		};
	},
	computed: {
		filteredActions() {
			// Mostrar acciones solo si portcallstatus no es igual a 0 ni 3
			return this.portcallstatus != 0 && this.portcallstatus != 3 ? this.actions : [];
		}
	},
	mounted() {
		this.parent = this.externalFilter.rules[0].data;
		for (let i = 0; i < this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST.length; i++) {
			if (this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST[i] == 'SHAG') this.rol = 'SHAG';
		}
		this.loadPortcallStatus();
	},
	methods: {
		filter() {
			let group1 = {
				groupOp: 'and',
				groups: [],
				rules: [
					{ field: 'statusid', op: 'ne', data: '1' },
					{ field: 'portcallid', op: 'eq', data: this.parent }
				]
			};

			let groups = [group1];
			if (this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS) {
				this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS.forEach((org) => {
					groups.push({
						groupOp: 'and',
						groups: [],
						rules: [
							{ field: 'statusid', op: 'eq', data: '1' },
							{
								field: 'creausercompany',
								op: 'cn',
								data: org
							},
							{
								field: 'creausercompany',
								op: 'nn'
							},
							{ field: 'portcallid', op: 'eq', data: this.parent }
						]
					});
				});
			}
			return { groupOp: 'or', rules: [], groups: groups };
		},
		loadPortcallStatus() {
			const opts = {
				model: 'portcalls',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'portcallid',
							op: 'eq',
							data: this.parent
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data) {
					this.portcallstatus = response.data.data[0].portcallstatus;
				}
			});
		}
	}
};
</script>
